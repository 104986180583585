@mixin page-width {
  max-width: var(--page-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}

@mixin underlined-on-hover {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: rgb(0, 0, 0, 0);
  text-decoration-thickness: .06em;
  transition: text-decoration-color .4s ease;
  @media (hover: hover) {
    &:hover {
      text-decoration-color: inherit;
    }
  }
}

@mixin underlined-link {
  text-decoration: underline;
  text-underline-position: under;
  text-underline-position: intial;
  text-decoration-thickness: .06em;
  transition: text-decoration-color .4s ease;
  @media (hover: hover) {
    &:hover {
      text-decoration-color: transparent;
    }
  }
}
/* VARIABLES
---------------------------------------------- */
:root {

  --vh:1;
  --arrows: flex;

  /* Fonts */
  --body-font: "ProvidedUntitledSansRegular", sans-serif;

  /* Font sizes */
  --font-size: 13px;

  /* Colors */
  --white: #fff;
  --black: black;

  /* Widths, paddings, gaps */
  --lr-page-padding: 30px;

  /* Set up */
  font-family: var(--body-font);
  font-size: var(--font-size);
  color: var(--black);
}

/* MOBILE CSS VARIABLES
---------------------------------------------- */
@media (max-width: 599px) {
  :root {
    /* Widths, paddings, gaps */
    --lr-page-padding: 16px;
  }
}

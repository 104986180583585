@import "fonts";
@import "reset";
@import "media-queries";
@import "variables";
@import "mixins";
@import "../node_modules/@splidejs/splide/dist/css/splide.min";

/* GLOBAL
---------------------------------------------- */
body {
  font-family: var(--body-font);
  font-size: var(--font-size);
  font-weight: 400;
  background-color: var(--white);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  -webkit-overflow-scrolling: touch;
  // height: 100vh;
}
.visually-hidden {
  position:absolute !important;
  overflow:hidden;
  width:1px;
  height:1px;
  margin:-1px;
  padding:0;
  border:0;
  clip:rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap:normal !important
}
.center {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
}
img {
  // width: 100%;
  // height: auto;
  display: block;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.menu-item button,
.menu-item #photo-number {
  margin: 0;
  border: 0;
  line-height: 1.1;
}
.underlined, 
p a,
.button a,
.menu-item a,
.menu-item button,
.firm-info a {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: transparent;
  text-decoration-thickness: .06em;
  transition: color .4s ease, text-decoration-color .4s ease;
  color: var(--black);
}
.underlined {
  text-decoration-color: inherit!important;
  @media (hover: hover) {
    &:hover
    {
      text-decoration-color: transparent!important;
    }
  }
}
.menu-item {

  a.underlined-on-hover:active,
  button.underlined-on-hover:active,
  a.underlined-on-hover:focus,
  button.underlined-on-hover:focus {
    text-decoration-color: inherit;
  }
  @media (hover: hover) {
    a.underlined-on-hover:hover,
    button.underlined-on-hover:hover
    {
      text-decoration-color: inherit;
    }
  }
}
.firm-info {
  a:hover,
  a:focus,
  a:active {
    text-decoration-color: inherit;
  }
  @media (hover: hover) {
    a:hover
    {
      text-decoration-color: inherit;
    }
  }
}
.underlined-on-hover a,
a.underlined-on-hover,
.underlined-on-hover button,
button.underlined-on-hover {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: transparent;
  text-decoration-thickness: .06em;
  transition: color .4s ease, text-decoration-color .4s ease;
  &.active-link {
    text-decoration-color: var(--black);
  }
  @media (hover: hover) {
    &:hover
    {
      text-decoration-color: var(--black);
    }
  }
}
p:not(:last-of-type) {
  margin-bottom: 1em;
}
p a {
  color: inherit;
}
.page-width {
  @include page-width;
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
#custom-cursor {
  position: fixed;
  color: var(--white);
  pointer-events: none;
  mix-blend-mode: difference;  
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  width:1px;
  height:1px;
  @media (hover: hover) {
    opacity: 1;
    width: initial;
    height: initial;
  }
  span {
    vertical-align: .1em
  }
}
.hidden {
  display: none;
}


/* Lazy loading
---------------------------------------------- */

[data-lazy] {
  visibility: hidden;
}

.image-wrap {
  background-size: cover;
  min-width: 0;
  background-color: var(--white);
}

/* Styling
---------------------------------------------- */

.projects {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1svh) * 100);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  margin-top: 7.5vh;
  margin-top: calc(var(--vh, 1vh) * 7.5);
  margin-top: calc(var(--vh, 1svh) * 7.5);
  margin-bottom: 22.5vh;
  margin-bottom: calc(var(--vh, 1vh) * 22.5);
  margin-bottom: calc(var(--vh, 1svh) * 22.5);
  &.blurred {
    filter: blur(36px);
  }
  .splide__track {
    height: 100%;
  }
  @include for-large-tablet-portrait-below {
    margin-bottom: 15vh;
    margin-bottom: calc(var(--vh, 1vh) * 15);
    margin-bottom: 15svh;
    margin-top: 12.5vh;
    margin-top: calc(var(--vh, 1vh) * 12.5);
    margin-top: 12.5svh;
  }
}

.bottom-padding {
  margin-bottom: 18px
}

/* -- HEADER -- */

/* Desktop */
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  color: #FFFEF9;
  mix-blend-mode: difference;
}

/* -- FOOTER -- */

/* Desktop */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  color: var(--white);
  mix-blend-mode: difference;
}

header, .footer {
  pointer-events: none;
}

header .menu-item, .footer .menu-item {
  pointer-events: initial;
}

/* -- MENU CONTENTS -- */
.menu-contents {
  display: flex;
  width: 100%;
  padding: 18px 14px 0px 14px;
  justify-content: space-between;
  @include for-small-tablet-below {
    padding: 2px;
  }
}
.footer .menu-contents {
  padding: 0px 14px 18px 14px;
  @include for-small-tablet-below {
    padding: 2px;
  }
}
.menu-contents.three-menu {
  justify-content: initial;
}
.menu-contents.three-menu > .menu-item {
  width: 33.33%;
}
.menu-contents.three-menu > .menu-item:nth-of-type(2) {
  text-align: center;
}
.menu-contents.three-menu > .menu-item:nth-of-type(3) {
  text-align: right;
}
.menu-item a, .menu-item span, .menu-item button {
  padding: 12px 16px;
  display: inline-block;
  color: var(--white);
  mix-blend-mode: difference;
}

/* -- FLICKITY -- */
.carousel-container {
  height: 70vh;
  height: calc(var(--vh, 1vh) * 70);
  height: 70svh;
  scroll-snap-align: start;
  padding-top: 15vh;
  padding-top: calc(var(--vh, 1vh) * 15);
  padding-top: 15svh;
  @include for-large-tablet-portrait-below {
    padding-top: 5vh;
    padding-top: calc(var(--vh, 1vh) * 5);
    padding-top: 5svh;
    &:last-of-type {
      padding-bottom: 10vh;
      padding-bottom: 10svh;
    }
  }
}

.carousel {
  height: 100%;
}
.carousel-cell {
  width: 100%;
  height: 100%;
  margin-right: 10px;
  background: transparent;
  counter-increment: carousel-cell;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-container .carousel-cell {
  align-items: start;
}
.carousel-cell-image {
  height: 55vh;
  height: calc(var(--vh, 1vh) * 55);
  width: auto;
  @include for-large-tablet-portrait-below {
    height: 40vh;
    height: calc(var(--vh, 1vh) * 40);
    height: 40svh;
    width: auto;
  }
}
.carousel-status {
  font-size: 13px;
}

/* The Project Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  cursor: pointer;
  // overflow-y: initial !important;
  overflow: scroll;
  @include for-large-tablet-portrait-below {
    overflow: hidden;
  }
}

/* Modal Content/Box */
.modal-content {
  margin-top: 53px; /* 15% from the top and centered */
  padding: 45px 30px;
  width: 100%; /* Could be more or less, depending on screen size */
  cursor: default;
  overflow: hidden;
  @include for-large-tablet-portrait-below {
    margin-top: 42px;
    padding: 13px 13px 20px 13px;
    .back-container {
      padding-bottom: 4rem;
    }
  }
}

.modal-body {
  display: grid;
  grid-template-columns: 1fr 3fr .2fr;
  grid-column-gap: 50px;
  overflow-y: scroll;
  @include for-large-tablet-portrait-below {
    max-height: calc(100vh - 117px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 117px);
  }
  @include for-small-tablet-below {
    display: block;
  }
}

.back-container {
  display: grid;
  padding-top: 2rem;
  grid-template-columns: 1fr 3fr .2fr;
  grid-column-gap: 50px;
  @include for-large-tablet-portrait-below {
    display: block;
  }
}
.modal-back-button {
  span {
    vertical-align: .1em
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
// .modal-body::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// .modal-body {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// }

/* The Info Modal (background) */
.info-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0); /* Black w/ opacity */
  cursor: pointer;
  overflow-y: initial !important;
  .modal-back-button {
    padding-left: 16px;
    padding-top: 1em;
    @include for-large-tablet-portrait-below {
      padding-left: 0;
    }
  }
}

/* Modal Content/Box */
.info-modal-content {
  float:left;
  padding: 30px;
  padding-right: 4px;
  margin-left: 104px;
  cursor: default;
}
.info-modal-body .firm-info {
  margin-bottom: 1rem
}
.info-modal-body div {
  padding: 0 16px;
}

.firm-info {
  img {
    width: 220px;
    height: auto;
  }
}

.left-button, .right-button {
  display: none;
}
.firm-title {
  text-align: left;
}
.project-title {
  text-align:right
}

.collaborators {
  &__container {
    min-height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    &.blurred {
      filter: blur(36px);
    }
  }
  &__wrapper {
    max-width: 750px;
    padding: var(--lr-page-padding);
    text-align: center;
  }
  &__title {
    margin-bottom: 1em;
  }
}

/* -- MEDIA QUIERIES -- */
@media only screen and (max-width: 1024px) {
  body {
    font-size: 11px;
  }
  p a {
    text-underline-position: auto;
  }
  header .menu-contents > .menu-item,
  .footer .menu-contents > .menu-item {
    width: 33.3%;
  }
  .menu-item a, .menu-item span, .menu-item button {
    padding: 10px 10px;
  }
  .info-modal-content {
    margin-top: 42px;
    margin-left: 0px;
    padding: 12px;
    width: 100%;
    display: block;
  }
  .info-modal-body div {
    display: block;
    padding: 0;
  }
  .info-modal-body .info-close-button {
    margin-top: 18px;
  }
  .info-close-button button {
    padding-top: 10px 0 0 10px;
  }
  .carousel-cell {
    align-items: center!important;
  }
}

@media only screen and (max-width: 800px) {
  body, .carousel-status, .menu-item button {
    font-size: 12px;
  }
  .left-button, .right-button {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 11;
    width: 12.5vw;
    pointer-events: none;
  }
  .right-button {
    left: initial;
    justify-content: flex-end;
    right: 0;
  }
  .left-button > div {
    padding-left: 12px;
  }
  .right-button > div {
    padding-right: 12px
  }
  #project-info > div:last-of-type {
    margin-bottom: 18px;
  }
  .close-button {
    position: initial;
  }
  .close-button button {
    padding: 10px 10px 10px 0px;
    display: inline-block;
  }
  .close {
    float: right;
    bottom: 0;
    right: 0;
    position: initial;
  }
  .carousel-cell-image {
    width: 75vw;
    height: auto;
  }
}

@media only screen and (max-width: 450px) {
  .top-hover, .bottom-hover {
    display: none;
  }
  .menu-item a, .menu-item span, .menu-item button {
    padding: 10px 10px;
  }
  .projects .carousel-container:last-of-type {
    padding-top: 0vh;
    padding-bottom: initial;
  }
}

